import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow, WireAccountNumbersFormData, WireLocationType } from 'elements/element-transfer/types';
import {
  CustomError,
  isMaxLengthOfBankName,
  isNoBankInfoFoundWithRoutingNumber,
  isOtherExceptions,
  isRoutingNumberDoesNotProcessFedwirePayments,
} from 'elements/api';
import { getParsedTraceId } from 'elements/element-transfer/utils/getParsedTraceId';
import {
  resetWithdrawalWireAccountNumbers,
  setCryptoWithdrawalIsAllowedExternalAddressBook,
  setPage,
  setWithdrawalDomesticWireAccountNumbers,
  setWithdrawalWireBankInfo,
  setWithdrawalWireRoutingNumberCustomError,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import trimAndRemoveExtraSpacesFormData from 'elements/element-transfer/utils/wireWithdrawalUtils/trimAndRemoveExtraSpacesFormData';

const useWireWithdrawalAccountNumbersPage = () => {
  const { t } = useTranslation();

  const { state, dispatch } = useElement();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTraceId, setErrorTraceId] = useState('');

  const initialFormData = state[TransferFlow.Withdrawal].WIRE.accountNumbers;
  const { bankInfo } = state[TransferFlow.Withdrawal].WIRE;
  const { locationType } = state[TransferFlow.Withdrawal].WIRE.recipientInfo;
  const isDomestic = locationType === WireLocationType.Domestic;

  const { routingNumberCustomError } = state[TransferFlow.Withdrawal].WIRE;

  const resetContextForWireAccountNumbersPage = () => {
    dispatch(setWithdrawalWireRoutingNumberCustomError(null));
    dispatch(resetWithdrawalWireAccountNumbers());
  };

  const getUserData = async () => {
    const { organization } = await api.transferElement.getUserData();
    dispatch(setCryptoWithdrawalIsAllowedExternalAddressBook(organization?.isAllowedExternalAddressBook || false));
  };

  const handleContinue = async (data: WireAccountNumbersFormData) => {
    let bankName;
    setLoading(true);

    try {
      if (isDomestic) {
        const response = await api.transferElement.getBankInfo(data.routingNumber);
        bankName = response.bankName;
      }
      await getUserData();
    } catch (e) {
      const errorResponse = e as CustomError;

      setError(true);
      if (errorResponse?.responseData?.traceId) {
        const tId = getParsedTraceId(errorResponse.responseData.traceId);
        if (tId) {
          setErrorTraceId(tId);
        }
      }

      if (isRoutingNumberDoesNotProcessFedwirePayments(errorResponse)) {
        setErrorMessage(t('backendErrorMessages.routingNumberDoesNotProcessFedwirePayments'));
      }

      if (isMaxLengthOfBankName(errorResponse)) {
        setErrorMessage(t('backendErrorMessages.bankNameCannotBeLongerThan'));
      }

      if (isNoBankInfoFoundWithRoutingNumber(errorResponse)) {
        setErrorMessage(t('backendErrorMessages.noBankInformationFound'));
      }

      if (isOtherExceptions(errorResponse)) {
        setErrorMessage(t('backendErrorMessages.otherExceptions'));
      }
      resetContextForWireAccountNumbersPage();

      setLoading(false);
      return;
    }

    dispatch(setWithdrawalWireRoutingNumberCustomError(null));
    dispatch(setWithdrawalDomesticWireAccountNumbers(trimAndRemoveExtraSpacesFormData(data)));
    if (isDomestic && bankName) {
      dispatch(setWithdrawalWireBankInfo({ ...bankInfo, bankName }));
    }

    dispatch(setPage(Paths.WithdrawalWireAmount));

    setLoading(false);
  };

  const handleBack = () => {
    dispatch(setPage(Paths.WithdrawalWireBankInfo));
  };

  const handleReload = () => {
    resetContextForWireAccountNumbersPage();
    setLoading(false);
    setError(false);
  };

  return {
    loading,
    error: { error, errorMessage, errorTraceId },
    initialFormData,
    isDomestic,
    routingNumberCustomError,
    handleContinue,
    handleReload,
    handleBack,
  };
};

export default useWireWithdrawalAccountNumbersPage;
