import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { ReactComponent as NoResultsIcon } from 'elements/element-transfer/assets/icons/no_results.svg';
import { BaseInput, Button, CircularProgress } from 'elements/components';
import BaseRadioGroup from 'elements/element-transfer/components/BaseRadioGroup';
import { DestroyActionModal } from 'elements/components/ConfirmDeleteModal/DestroyActionModal';
import { CryptoAddressResponse } from 'elements/element-transfer/types';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { getUniqueIdentifierForCustodialAccountBalanceModel } from 'utils/account-balances/get-unique-identifier-for-custodial-account-balance-model';
import RadioLabel from './RadioLabel';

import { StyledButton, StyledLoaderContainer } from './styled';

type Props = {
  search: string;
  recipients: Array<CryptoAddressResponse>;
  isRecipientsLoading: boolean;
  isDeleteLoading: boolean;
  recipientId?: string;
  setRecipientId: (id: string) => void;
  isAllowedExternalAddressBook: boolean;
  lastItemRef: React.MutableRefObject<HTMLDivElement | null>;
  onSearch: (value: string, network: string, asset: string) => void;
  onDeleteRecipient: (id: string) => void;
  setManualInput: () => void;
  selectedCoin: string;
  coinList: CustodialAccountBalanceModel[];
};

const CryptoRecipientSection: React.FC<Props> = ({
  search,
  recipients,
  isRecipientsLoading,
  isDeleteLoading,
  recipientId,
  setRecipientId,
  isAllowedExternalAddressBook,
  lastItemRef,
  onSearch,
  onDeleteRecipient,
  setManualInput,
  selectedCoin,
  coinList,
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState(search);
  const [deleteRecipientId, setDeleteRecipientId] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecipientName, setDeleteRecipientName] = useState('');
  const formattedRadioValues = useMemo(
    () => recipients.map(rec => ({ value: rec.id, label: rec.id, data: rec })),
    [recipients],
  );

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onSearchClick = () => {
    const coin = coinList.find(cab => getUniqueIdentifierForCustodialAccountBalanceModel(cab) === selectedCoin)!;

    const trimmedValue = searchValue.trim();
    setSearchValue(trimmedValue);
    setRecipientId('');
    onSearch(trimmedValue, coin.network, coin.assetTicker);
  };

  const onRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecipientId(e.target.value);
  };

  const onDeleteClick = (deleteId: string) => {
    const name = formattedRadioValues.find(val => val.data.id === deleteId)?.data.name;
    if (name) {
      setDeleteRecipientName(name);
    }
    setDeleteRecipientId(deleteId);
    setIsDeleteModalOpen(true);
  };

  const onConfirmDeleteClick = async () => {
    await onDeleteRecipient(deleteRecipientId);
    setIsDeleteModalOpen(false);
  };

  const onCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    setSearchValue(search);
  }, [search]);

  return (
    <Stack gap="16px" mb="24px">
      <Typography variant="overlineDefaultMedium" color="secondary.font">
        {t('elements.transfer.commonRecipientSection.subtitle')}
      </Typography>
      {isAllowedExternalAddressBook && (
        <>
          <Button onClick={setManualInput}>
            <Typography variant="buttonLarge">
              {t('elements.transfer.withdrawalCryptoRecipientSearchSection.addRecipientBtnTitle')}
            </Typography>
          </Button>
          <Typography variant="overlineDefaultMedium" color="secondary.font" textAlign="center">
            {t('elements.transfer.commonRecipientSection.or')}
          </Typography>
        </>
      )}
      <Stack direction="row" gap="18px" paddingBottom="24px">
        <BaseInput
          name="searchExternalAccount"
          value={searchValue}
          onChange={onSearchInputChange}
          placeholder={t('elements.transfer.withdrawalCryptoRecipientSearchSection.searchInputHelperText')}
        />
        <StyledButton disabled={!searchValue.trim().length} onClick={onSearchClick}>
          <Typography variant="buttonLarge">{t('elements.transfer.commonRecipientSection.searchBtnTitle')}</Typography>
        </StyledButton>
      </Stack>
      {search && !isRecipientsLoading && (
        <Typography variant="overlineDefaultMedium" color="secondary.font">
          {t('elements.transfer.commonRecipientSection.results')}
        </Typography>
      )}

      {search && !isRecipientsLoading && !recipients.length && (
        <Stack alignItems="center">
          <NoResultsIcon />
          <Typography variant="h6" color="grey.500">
            {t('elements.transfer.commonRecipientSection.noResultsFound')}
          </Typography>
          <Typography variant="bodyDefaultBook" color="secondary.font">
            {t('elements.transfer.commonRecipientSection.pleaseTryAgain')}
          </Typography>
        </Stack>
      )}

      {search && !!recipients.length && (
        <>
          <BaseRadioGroup
            labelledby="recipients-radio-group"
            name="recipients"
            value={recipientId || ''}
            onChange={onRecipientChange}
            values={formattedRadioValues}
            radioLabelNode={RadioLabel}
            onDelete={isAllowedExternalAddressBook ? onDeleteClick : undefined}
            withHover
          />
          <div id="intersection-observer-trigger" ref={lastItemRef} />
          <DestroyActionModal
            onClose={onCloseModal}
            title={t('elements.transfer.commonRecipientSection.deleteConfirmModal.title')}
            captionText={t('elements.transfer.withdrawalCryptoRecipientSearchSection.deleteConfirmModal.subtitle')}
            mainText={deleteRecipientName}
            isOpen={isDeleteModalOpen}
            destroyButtonTitle={t('elements.transfer.commonRecipientSection.deleteConfirmModal.confirmButtonLabel')}
            onDestroyButtonClick={onConfirmDeleteClick}
            destroyButtonLoading={isDeleteLoading}
          />
        </>
      )}

      {isRecipientsLoading && (
        <StyledLoaderContainer>
          <CircularProgress />
        </StyledLoaderContainer>
      )}
    </Stack>
  );
};

export default CryptoRecipientSection;
