import React from 'react';
import {
  CombinedCryptoAsset,
  CombinedCryptoAssetNetwork,
  TCombinedCryptoAsset,
  TCombinedCryptoNetwork,
} from 'elements/types';
import {
  cryptoAssetIconMapping,
  getCombinedCryptoAssetNetworkType,
  tradeCoinAssetNetworkIconMapping,
} from 'elements/utils';
import i18n from 'localizations';
import { ReactComponent as AAVEUniqueIdsIcon } from 'elements/assets/icons/coins/AAVEUniqueIds.svg';
import { ReactComponent as SolanaUniqueIdsIcon } from 'elements/assets/icons/coins/solanaUniqueIds.svg';
import { ReactComponent as TheGraphUniqueIdsIcon } from 'elements/assets/icons/coins/theGraphUniqueIds.svg';
import { ReactComponent as DogecoinUniqueIdsIcon } from 'elements/assets/icons/coins/dogecoinUniqueIds.svg';
import { ReactComponent as ShibaInuCoinUniqueIdsIcon } from 'elements/assets/icons/coins/shibaInuCoinUniqueIds.svg';
import { ReactComponent as AvalancheUniqueIdsIcon } from 'elements/assets/icons/coins/avalancheUniqueIds.svg';

export const getCoinData = (
  asset: TCombinedCryptoAsset,
  network: TCombinedCryptoNetwork,
  withUniqueIdsIcon = false,
) => {
  const coinNameMapping = {
    [CombinedCryptoAsset.ether]: i18n.t('elements.features.coinLabels.ethereum'),
    [CombinedCryptoAsset.matic]: i18n.t('elements.features.coinLabels.polygon'),
    [CombinedCryptoAsset.florins]: i18n.t('elements.features.coinLabels.florins'),
    [CombinedCryptoAsset.bitcoin]: i18n.t('elements.features.coinLabels.bitcoin'),
    [CombinedCryptoAsset.usdCoin]: i18n.t('elements.features.coinLabels.usdCoin'),
    [CombinedCryptoAsset.solana]: i18n.t('elements.features.coinLabels.solana'),
    [CombinedCryptoAsset.wrappedETH]: i18n.t('elements.features.coinLabels.wrappedETH'),
    [CombinedCryptoAsset.ada]: i18n.t('elements.features.coinLabels.cardano'),
    [CombinedCryptoAsset.avax]: i18n.t('elements.features.coinLabels.avalanche'),
    [CombinedCryptoAsset.shib]: i18n.t('elements.features.coinLabels.shibaInuCoin'),
    [CombinedCryptoAsset.bat]: i18n.t('elements.features.coinLabels.basicAttentionToken'),
    [CombinedCryptoAsset.dot]: i18n.t('elements.features.coinLabels.polkadot'),
    [CombinedCryptoAsset.bnb]: i18n.t('elements.features.coinLabels.bnb'),
    [CombinedCryptoAsset.busd]: i18n.t('elements.features.coinLabels.binanceUSD'),
    [CombinedCryptoAsset.usdt]: i18n.t('elements.features.coinLabels.usdt'),
    [CombinedCryptoAsset.xlm]: i18n.t('elements.features.coinLabels.stellar'),
    [CombinedCryptoAsset.link]: i18n.t('elements.features.coinLabels.chainlink'),
    [CombinedCryptoAsset.algo]: i18n.t('elements.features.coinLabels.algorand'),
    [CombinedCryptoAsset.ltc]: i18n.t('elements.features.coinLabels.litecoin'),
    [CombinedCryptoAsset.uni]: i18n.t('elements.features.coinLabels.uniswap'),

    [CombinedCryptoAsset.doge]: i18n.t('elements.features.coinLabels.dogecoin'),
    [CombinedCryptoAsset.crv]: i18n.t('elements.features.coinLabels.curveDAOToken'),
    [CombinedCryptoAsset.aave]: i18n.t('elements.features.coinLabels.AAVE'),
    [CombinedCryptoAsset.bch]: i18n.t('elements.features.coinLabels.bitcoinCash'),
    [CombinedCryptoAsset.xtz]: i18n.t('elements.features.coinLabels.tezos'),
    [CombinedCryptoAsset.comp]: i18n.t('elements.features.coinLabels.compound'),

    [CombinedCryptoAsset.grt]: i18n.t('elements.features.coinLabels.theGraph'),
    [CombinedCryptoAsset.atom]: i18n.t('elements.features.coinLabels.cosmos'),
  };

  const coinAssetNetworkNameMapping = {
    // USD Coin
    [CombinedCryptoAssetNetwork.usdc_mainnet]: i18n.t('elements.features.coinLabels.usdCoinOnEthereum'),
    [CombinedCryptoAssetNetwork.usdc_goerli]: i18n.t('elements.features.coinLabels.usdCoinOnEthereum'),
    [CombinedCryptoAssetNetwork.usdc_sepolia]: i18n.t('elements.features.coinLabels.usdCoinOnEthereum'),
    // USD Coin on Polygon
    [CombinedCryptoAssetNetwork.usdc_polygonMainnet]: i18n.t('elements.features.coinLabels.usdCoinOnPolygon'),
    [CombinedCryptoAssetNetwork.usdc_polygonMumbai]: i18n.t('elements.features.coinLabels.usdCoinOnPolygon'),
  };

  // @ts-ignore
  const cryptoAssetUniqueIconsMap = {
    [CombinedCryptoAsset.solana]: {
      name: i18n.t('elements.features.coinLabels.solana'),
      icon: SolanaUniqueIdsIcon,
    },
    [CombinedCryptoAsset.aave]: {
      name: i18n.t('elements.features.coinLabels.AAVE'),
      icon: AAVEUniqueIdsIcon,
    },
    [CombinedCryptoAsset.grt]: {
      name: i18n.t('elements.features.coinLabels.theGraph'),
      icon: TheGraphUniqueIdsIcon,
    },
    [CombinedCryptoAsset.doge]: {
      name: i18n.t('elements.features.coinLabels.dogecoin'),
      icon: DogecoinUniqueIdsIcon,
    },
    [CombinedCryptoAsset.shib]: {
      name: i18n.t('elements.features.coinLabels.shibaInuCoin'),
      icon: ShibaInuCoinUniqueIdsIcon,
    },
    [CombinedCryptoAsset.avax]: {
      name: i18n.t('elements.features.coinLabels.avalanche'),
      icon: AvalancheUniqueIdsIcon,
    },
  } as Record<TCombinedCryptoAsset, { name: string; icon: React.ReactNode }>;

  if (asset === CombinedCryptoAsset.usdCoin) {
    const assetNetworkPair = getCombinedCryptoAssetNetworkType(asset, network);

    return {
      // @ts-ignore
      name: coinAssetNetworkNameMapping[assetNetworkPair],
      // @ts-ignore
      icon: tradeCoinAssetNetworkIconMapping[assetNetworkPair],
    };
  }

  // TODO consider to create general approach for resolving this issue
  // this additional solana icon with unique ids is needed to resolve bug which appears in Safari and is connected with inner Safari processes operating with svg ids
  // to prevent gradient disappearing we should have two icons with unique ids rendering at one moment of time on the page
  if (withUniqueIdsIcon && cryptoAssetUniqueIconsMap[asset]) {
    return {
      name: cryptoAssetUniqueIconsMap[asset].name,
      icon: cryptoAssetUniqueIconsMap[asset].icon,
    };
  }

  return {
    name: coinNameMapping[asset],
    icon: cryptoAssetIconMapping[asset],
  };
};
