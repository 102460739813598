import React, { useState, useEffect } from 'react';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow, WireLocationType, WireWithdrawalAdditionalDetails } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/WireAmountPage';
import {
  setPage,
  setWithdrawalWireAdditionalDetails,
  setWithdrawalWireAmount,
  useElement,
  setLockId,
  setPaymentType,
} from 'elements/element-transfer/contexts/Element';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';
import * as api from 'elements/element-transfer/api';
import { useLockPaymentAmount } from 'elements/hooks/useLockPaymentAmount';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { Currency } from 'ui-enums/response/transactions/currency';

const WireAmountPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { dispatch, state } = useElement();

  const { recipientId, amount, note, memo, purposeOfPayment, recipientInfo } = state[TransferFlow.Withdrawal].WIRE;
  const { isAllowedExternalAddressBook } = state[TransferFlow.Withdrawal].CRYPTO;

  const isDomestic = recipientInfo.locationType === WireLocationType.Domestic;

  const { lock, unlock, locking, unlocking, lockError, unlockError, clearLockError } = useLockPaymentAmount({
    lockId: state.lockId,
    setLockId: (id: string) => dispatch(setLockId(id)),
    lockRequest: api.transferElement.lockPaymentAmount,
    unlockRequest: api.transferElement.unlockPaymentAmount,
  });

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  const onBack = (): void => {
    dispatch(setPage(recipientId ? Paths.WithdrawalWireRecipientSearch : Paths.WithdrawalWireAccountNumbers));
  };

  const onReview = async (values: WireWithdrawalAdditionalDetails & { amount: string }): Promise<void> => {
    const { amount: _amount, ...restValues } = values;
    const type = isDomestic ? PaymentType.wireWithdrawal : PaymentType.internationalWireWithdrawal;
    const isLocked = await lock({ paymentType: type, usdAmount: +_amount, assetType: Currency.usd });

    if (isLocked) {
      dispatch(setWithdrawalWireAmount(_amount));
      dispatch(setWithdrawalWireAdditionalDetails(restValues));
      dispatch(setPage(Paths.WithdrawalWireReview));
    }
  };

  useEffect(() => {
    dispatch(setPaymentType(PaymentType.withdrawalCrypto));
  }, [dispatch]);

  return (
    <>
      <ContentBlock
        recipientId={recipientId}
        amount={amount}
        isDomestic={isDomestic}
        isAllowedExternalAddressBook={isAllowedExternalAddressBook || false}
        custodialAccount={state.custodialAccount}
        note={note}
        memo={memo}
        purposeOfPayment={purposeOfPayment}
        lockError={lockError}
        unlockAmountError={unlockError}
        lockingAmount={locking}
        unlockingAmount={unlocking}
        onBack={onBack}
        onClose={onCustomClose}
        onReview={onReview}
        onReload={unlock}
        {...(lockError ? { clearLockError } : {})}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default WireAmountPage;
