import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { setErrorBanner } from 'elements/utils';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/CryptoAmountPage';
import {
  resetCryptoWithdrawalRecipientData,
  setCryptoWithdrawalIsAllowedExternalAddressBook,
  setCryptoWithdrawalIsManual,
  setCryptoWithdrawalRecipientSearchValue,
  setLockId,
  setPage,
  setPaymentType,
  setWithdrawalCryptoCoinAmount,
  setWithdrawalCryptoCoinList,
  setWithdrawalCryptoDestinationTag,
  setWithdrawalCryptoRecipientName,
  setWithdrawalCryptoSelectedCoin,
  setWithdrawalCryptoWalletAddress,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';
import { useLockPaymentAmount } from 'elements/hooks/useLockPaymentAmount';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { getUniqueIdentifierForCustodialAccountBalanceModel } from 'utils/account-balances/get-unique-identifier-for-custodial-account-balance-model';
import { OperationTypeModel } from 'models/enums/assets/operation-type-model';
import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import useCryptoWithdrawalRecipientSearchPage from 'elements/element-transfer/hooks/useCryptoWithdrawalRecipientSearchPage';
import { isAlreadyExists } from 'elements/api';
import i18n from 'localizations';

const CryptoAmountPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();

  const { coinList, selectedCoin, amount, walletAddress, destinationTag, isManualInput, isAllowedExternalAddressBook } =
    state[TransferFlow.Withdrawal].CRYPTO;
  const {
    search,
    recipientList,
    isRecipientsLoading,
    isRecipientSaveLoading,
    isDeleteLoading,
    recipientId,
    lastItemRef,
    onSearch,
    onSelectRecipient,
    onDeleteRecipient,
    onSaveNewRecipient,
  } = useCryptoWithdrawalRecipientSearchPage();
  const [getCoinListError, setGetCoinListError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { lock, unlock, locking, unlocking, lockError, unlockError, clearLockError } = useLockPaymentAmount({
    lockId: state.lockId,
    setLockId: (id: string) => dispatch(setLockId(id)),
    lockRequest: api.transferElement.lockPaymentAmount,
    unlockRequest: api.transferElement.unlockPaymentAmount,
  });

  const requestUserData = async () => {
    try {
      const { organization } = await api.transferElement.getUserData();
      dispatch(setCryptoWithdrawalIsAllowedExternalAddressBook(organization?.isAllowedExternalAddressBook || false));
    } catch (e) {
      setErrorBanner(e, enqueueSnackbar);
      const withBackNavigation = state.transferOptionsList.length > 1;
      if (withBackNavigation) {
        dispatch(setPage(Paths.Transfer));
      }
      setIsLoading(false);
    }
  };

  const requestCoinList = async () => {
    try {
      setIsLoading(true);
      setGetCoinListError(false);

      const coins = (
        await api.transferElement.getCoinsBalance(state.custodialAccountId, OperationTypeModel.withdrawal)
      ).data.filter(cab => cab.assetType.toLowerCase() === TrustAssetType.cryptoCurrency.toLowerCase());

      dispatch(setWithdrawalCryptoCoinList(coins));
      if (coins.length) {
        dispatch(setWithdrawalCryptoSelectedCoin(coins[0]));
      }

      setIsLoading(false);
    } catch (e) {
      const isBannerShown = setErrorBanner(e, enqueueSnackbar);
      const withBackNavigation = state.transferOptionsList.length > 1;
      if (!isBannerShown || !withBackNavigation) {
        setGetCoinListError(true);
      }
      if (withBackNavigation) {
        dispatch(setPage(Paths.Transfer));
      }
      setIsLoading(false);
    }
  };

  const onReview = async (
    formValues: {
      amount: string;
      cabId: string;
      walletAddress?: string;
      destinationTag?: string;
      cryptoAddressName?: string;
    },
    id?: string,
  ) => {
    const chosenCab = coinList.find(
      cab => getUniqueIdentifierForCustodialAccountBalanceModel(cab) === formValues.cabId,
    );

    if (!chosenCab) {
      return;
    }

    if (state.paymentType) {
      const isLocked = await lock({
        paymentType: state.paymentType,
        cryptoAmount: +formValues.amount,
        network: chosenCab.network,
        assetType: chosenCab.assetTicker,
      });

      if (!isLocked) {
        return;
      }
    }

    if (formValues.walletAddress && formValues.destinationTag !== undefined) {
      if (formValues.cryptoAddressName) {
        try {
          await onSaveNewRecipient({
            address: formValues.walletAddress,
            destinationTag: formValues.destinationTag,
            name: formValues.cryptoAddressName,
            network: chosenCab.network,
            asset: chosenCab.assetTicker,
          });
          dispatch(setWithdrawalCryptoRecipientName(formValues.cryptoAddressName));
        } catch (err) {
          if (isAlreadyExists(err.responseData)) {
            setErrorBanner(err, enqueueSnackbar, true, i18n.t('serverError.addressAlreadyExists'));
            return;
          }
          setErrorBanner(err, enqueueSnackbar, true);
          return;
        }
      }
      dispatch(setWithdrawalCryptoWalletAddress(formValues.walletAddress));
      dispatch(setWithdrawalCryptoDestinationTag(formValues.destinationTag));
    }

    if (id) {
      onSelectRecipient(id);
    }

    dispatch(setWithdrawalCryptoCoinAmount(formValues.amount));
    dispatch(setWithdrawalCryptoSelectedCoin(chosenCab));

    dispatch(setPage(Paths.WithdrawalCryptoReview));
  };

  const onReload = async () => {
    await requestUserData();
    if (getCoinListError) {
      await requestCoinList();
    }

    if (unlockError) {
      await unlock();
    }
  };

  const onBack = () => {
    dispatch(resetCryptoWithdrawalRecipientData());
    dispatch(setCryptoWithdrawalRecipientSearchValue(''));
    dispatch(setCryptoWithdrawalIsManual(false));
    dispatch(setPage(Paths.Transfer));
  };

  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await requestUserData())();
    if (!coinList.length) {
      // eslint-disable-next-line no-return-await
      (async () => await requestCoinList())();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setPaymentType(PaymentType.withdrawalCrypto));
  }, [dispatch]);

  return isLoading || unlocking ? (
    <LoadingPage onClose={onClose} />
  ) : (
    <ContentBlock
      amount={amount}
      isAllowedExternalAddressBook={isAllowedExternalAddressBook || false}
      selectedCoin={selectedCoin}
      coinList={coinList}
      walletAddress={walletAddress}
      destinationTag={destinationTag}
      getCoinListError={getCoinListError}
      lockAmountError={lockError}
      unlockAmountError={unlockError}
      lockingAmount={locking}
      onReview={onReview}
      onReload={onReload}
      onBack={onBack}
      onClose={onClose}
      {...(lockError ? { clearLockError } : {})}
      search={search}
      recipients={recipientList}
      isRecipientsLoading={isRecipientsLoading}
      isDeleteLoading={isDeleteLoading}
      isRecipientSaveLoading={isRecipientSaveLoading}
      recipientId={recipientId}
      lastItemRef={lastItemRef}
      onSearch={onSearch}
      onDeleteRecipient={onDeleteRecipient}
      onSaveNewRecipient={onSaveNewRecipient}
      isManualInput={isManualInput}
    />
  );
};

export default CryptoAmountPage;
