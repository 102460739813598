import { Currency } from 'ui-enums/response/transactions/currency';
import { NetworkChain } from 'ui-enums/response/network-chain';

export const getParsedCoinAssetType = (cabId: string): string | null => {
  if (!cabId) {
    return null;
  }

  const coinNetworkPair = cabId.split('/');
  let coinAssetType = coinNetworkPair[0].toLowerCase();
  const coinAssetNetwork = coinNetworkPair[1].toLowerCase();

  if (coinAssetType === Currency.usdc) {
    if (
      coinAssetNetwork === NetworkChain.solanaDevnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.solanaTestnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.solanaMainnet.toLowerCase()
    ) {
      coinAssetType = Currency.sol;
    }

    if (
      coinAssetNetwork === NetworkChain.stellarMainnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.stellarTestnet.toLowerCase()
    ) {
      coinAssetType = Currency.xlm;
    }

    if (
      coinAssetNetwork === NetworkChain.tronMainnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.tronTestnet.toLowerCase()
    ) {
      coinAssetType = Currency.tron;
    }

    if (
      coinAssetNetwork === NetworkChain.flowMainnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.flowTestnet.toLowerCase()
    ) {
      coinAssetType = Currency.flow;
    }

    if (
      coinAssetNetwork === NetworkChain.algorandTestnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.algorandMainnet.toLowerCase()
    ) {
      coinAssetType = Currency.algo;
    }

    if (
      coinAssetNetwork === NetworkChain.avalancheFuji.toLowerCase() ||
      coinAssetNetwork === NetworkChain.avalancheMainnet.toLowerCase()
    ) {
      coinAssetType = Currency.avax;
    }

    if (
      coinAssetNetwork === NetworkChain.polygonMainnet.toLowerCase() ||
      coinAssetNetwork === NetworkChain.polygonAmoy.toLowerCase() ||
      coinAssetNetwork === NetworkChain.polygonMumbai.toLowerCase()
    ) {
      coinAssetType = Currency.pol;
    }

    if (
      coinAssetNetwork === NetworkChain.sepolia.toLowerCase() ||
      coinAssetNetwork === NetworkChain.arbitrumSepolia.toLowerCase()
    ) {
      coinAssetType = Currency.eth;
    }
  }

  return coinAssetType;
};
