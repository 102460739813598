import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';

import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';

import { setErrorBanner } from 'elements/utils';
import { DEFAULT_PAGE_COUNT, FIRST_PAGE } from 'elements/constants';
import {
  mergeWithdrawalWireRecipientData,
  resetWithdrawalWireRecipientData,
  resetWithdrawalWireRecipients,
  setCryptoWithdrawalIsAllowedExternalAddressBook,
  setPage,
  setWithdrawalWireRecipients,
  setWithdrawalWireRecipientSearchValue,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import { formExternalAccountsSearchParams } from 'elements/element-transfer/utils/formExternalAccountsSearchParams';

import useInfiniteScroll from './useInfiniteScroll';

const useWireWithdrawalRecipientSearchPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();
  const {
    search,
    recipientId,
    recipients: { list: recipientList, page: pageFromStore, pageCount: pageCountFromStore },
  } = state[TransferFlow.Withdrawal].WIRE;

  const [isRecipientsLoading, setIsRecipientsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [lastItem, setLastItem] = useState<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const getExternalAccounts = async (value: string, page: number, pageCount: number) => {
    if (page > pageCount && pageCount !== 0) return;
    try {
      setIsRecipientsLoading(true);

      const { data: externalAccountList, meta } = await api.transferElement.getExternalAccounts(
        formExternalAccountsSearchParams(value, state.identityId, page),
      );

      dispatch(setWithdrawalWireRecipients({ list: externalAccountList, page, pageCount: meta?.pageCount || 0 }));
    } catch (err) {
      setErrorBanner(err, enqueueSnackbar, true);
    } finally {
      setIsRecipientsLoading(false);
    }
  };

  const onAddNewRecipient = () => {
    if (recipientId) {
      dispatch(resetWithdrawalWireRecipientData());
    }
    dispatch(setPage(Paths.WithdrawalWireRecipientInfo));
  };

  const onDeleteRecipient = async (id: string) => {
    try {
      setIsDeleteLoading(true);
      await api.transferElement.deleteExternalAccount(id);

      await onSearch(search);
    } catch (err) {
      setErrorBanner(err, enqueueSnackbar, true);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const onBack = () => dispatch(setPage(Paths.Transfer));

  const onSearch = async (value: string) => {
    dispatch(resetWithdrawalWireRecipients());
    dispatch(setWithdrawalWireRecipientSearchValue(value));

    await getExternalAccounts(value, FIRST_PAGE, DEFAULT_PAGE_COUNT);
  };

  const getUserData = async () => {
    try {
      const { organization } = await api.transferElement.getUserData();
      dispatch(setCryptoWithdrawalIsAllowedExternalAddressBook(organization?.isAllowedExternalAddressBook || false));
    } catch (e) {
      setErrorBanner(e, enqueueSnackbar, true);
    }
  };

  const onContinue = async (id: string) => {
    dispatch(resetWithdrawalWireRecipientData());
    const recipient = recipientList.find(rec => rec.id === id);

    if (recipient) {
      await getUserData();
      dispatch(mergeWithdrawalWireRecipientData(recipient));
      dispatch(setPage(Paths.WithdrawalWireAmount));
    }
  };

  const getExternalAccountsCallback = useCallback(
    () => getExternalAccounts(search, pageFromStore + 1, pageCountFromStore),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, pageFromStore, pageCountFromStore],
  );

  useInfiniteScroll(lastItem, getExternalAccountsCallback);

  // is used to resolve the problem of getting ref of the last component at conditional rendering of the recipients list
  useEffect(() => {
    setLastItem(lastItemRef?.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientList]);

  return {
    search,
    recipientList,
    isRecipientsLoading,
    isDeleteLoading,
    recipientId,
    lastItemRef,
    onBack,
    onAddNewRecipient,
    onSearch,
    onContinue,
    onDeleteRecipient,
  };
};

export default useWireWithdrawalRecipientSearchPage;
