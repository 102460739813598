import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Backdrop, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import useForm from 'elements/hooks/useForm';
import { Button, NoteField, NumberInput, Spacer } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import BaseStepper from 'elements/element-transfer/components/BaseStepper';
import Layout from 'elements/element-transfer/components/Layout';
import { MAX_MEMO_LENGTH, MAX_PURPOSE_OF_PAYMENT, useYupRules } from 'elements/element-transfer/hooks/useYupRules';
import { WireWithdrawalAdditionalDetails } from 'elements/element-transfer/types';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { ErrorState } from 'elements/features/LoadingErrorState';
import WireSource from '../WireSource';
import TextWithTooltipInfo from '../TextWithTooltipInfo';

type Props = {
  recipientId?: string;
  amount: string;
  isDomestic: boolean;
  isAllowedExternalAddressBook: boolean;
  custodialAccount: CustodialAccount | null;
  note: string;
  purposeOfPayment: string;
  memo: string;
  lockError: boolean;
  unlockAmountError: boolean;
  lockingAmount: boolean;
  unlockingAmount: boolean;
  onBack: () => void;
  onClose: () => void;
  onReview: (values: WireWithdrawalAdditionalDetails & { amount: string }) => void;
  onReload: () => void;
  clearLockError?: () => void;
};

const WireAmountPage: React.FC<Props> = ({
  recipientId,
  amount,
  isDomestic,
  isAllowedExternalAddressBook,
  custodialAccount,
  note,
  purposeOfPayment,
  memo,
  lockError,
  unlockAmountError,
  lockingAmount,
  unlockingAmount,
  onBack,
  onClose,
  onReview,
  onReload,
  clearLockError,
}) => {
  const { t } = useTranslation();
  const theme = useTheme<ElementsCustomTheme>();

  const { wireWithdrawalAmountRule, baseOptionalRule, purposeOfPaymentRule } = useYupRules();

  const {
    formik: { values, submitForm, setFieldValue, validateForm },
    helpers: { isFormDisabled, getFieldProps, getFieldErrorProps, getFieldPropsNoChange },
  } = useForm({
    initialValues: {
      amount,
      note,
      purposeOfPayment,
      memo,
    },
    yupShape: {
      amount: wireWithdrawalAmountRule(custodialAccount?.balance || 0, lockError, isAllowedExternalAddressBook),
      note: baseOptionalRule,
      purposeOfPayment: isDomestic ? baseOptionalRule : purposeOfPaymentRule,
      memo: baseOptionalRule,
    },
    onSubmit: (_values, { setSubmitting }) => {
      setSubmitting(false);
      onReview(_values);
    },
  });

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockError]);

  const onNoteChange = (value: string) => setFieldValue('note', value);
  const onPurposeOfPaymentChange = (value: string) => setFieldValue('purposeOfPayment', value);
  const onMemoChange = (value: string) => setFieldValue('memo', value);
  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }) => {
    clearLockError?.();
    setFieldValue('amount', event.target.value);
  };

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.withdrawalWireAmountPageTitle')}
      onBack={onBack}
      onClose={onClose}
      isContentCentered={unlockAmountError || unlockingAmount}
      footerButtons={
        !unlockAmountError &&
        !unlockingAmount && (
          <Stack gap="16px">
            {!recipientId && (
              <BaseStepper
                variant="dots"
                position="static"
                steps={4}
                activeStep={3}
                backButton={null}
                nextButton={null}
              />
            )}
            <Button
              isLoading={lockingAmount}
              type="submit"
              disabled={isFormDisabled || !Number(values.amount)}
              onClick={submitForm}
            >
              <Typography variant="buttonLarge">
                {t('elements.transfer.withdrawalWireAmountPage.reviewButton')}
              </Typography>
              <ArrowForwardIcon />
            </Button>
          </Stack>
        )
      }
    >
      {unlockingAmount && <CircularProgress sx={{ alignSelf: 'center' }} />}
      {unlockAmountError && <ErrorState onReload={onReload} />}
      {!unlockingAmount && !unlockAmountError && (
        <>
          <Backdrop
            open={lockingAmount}
            sx={{ backgroundColor: theme.palette.background.whiteTransparent50, zIndex: 5, position: 'absolute' }}
          />
          <NumberInput
            prefix="$"
            decimalScale={2}
            {...getFieldProps('amount')}
            {...getFieldErrorProps('amount')}
            onChange={onAmountChange}
            placeholder="$0.00"
            label={t('elements.transfer.withdrawalWireAmountPage.inputLabel')}
          />
          <Spacer size={16} />
          <NoteField
            {...getFieldPropsNoChange('note')}
            onChange={onNoteChange}
            label={t('elements.transfer.withdrawalWireAmountPage.internalNoteLabel')}
            addButtonName={t('elements.transfer.withdrawalWireAmountPage.addInternalNoteButton')}
            helperText={t('elements.transfer.withdrawalWireAmountPage.internalNoteHelperText')}
          />

          <Spacer size={32} />

          {!!custodialAccount && (
            <>
              <WireSource custodialAccount={custodialAccount} withBalance />
              <Spacer size={32} />
            </>
          )}

          {!isDomestic && (
            <>
              <NoteField
                {...getFieldPropsNoChange('purposeOfPayment')}
                {...getFieldErrorProps('purposeOfPayment')}
                onChange={onPurposeOfPaymentChange}
                label={`${t('elements.transfer.withdrawalWireAmountPage.purposeOfPayment')}*`}
                multiline
                maxLength={MAX_PURPOSE_OF_PAYMENT}
              />
              <Spacer size={8} />
              <TextWithTooltipInfo
                text={t('elements.transfer.withdrawalWireAmountPage.purposeOfPaymentHelperText')}
                tooltipTitle={t('elements.transfer.withdrawalWireAmountPage.purposeOfPayment')}
                tooltipTexts={[
                  t('elements.transfer.withdrawalWireAmountPage.purposeOfPaymentTooltipText1'),
                  t('elements.transfer.withdrawalWireAmountPage.purposeOfPaymentTooltipText2'),
                ]}
              />
              <Spacer size={32} />
            </>
          )}

          <NoteField
            {...getFieldPropsNoChange('memo')}
            onChange={onMemoChange}
            label={t('elements.transfer.withdrawalWireAmountPage.memoLabel')}
            helperText={t('elements.transfer.withdrawalWireAmountPage.memoHelperText')}
            multiline
            maxLength={MAX_MEMO_LENGTH}
          />
        </>
      )}
    </Layout>
  );
};

export default WireAmountPage;
